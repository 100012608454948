@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html,
	body {
		scroll-behavior: smooth;
	}

	/* Typography */
	.h1-web {
		font-weight: 400;
		font-size: 2.25rem;
		line-height: 1.4;
	}
	@media screen and (max-width: 767px) {
		.h1-web {
			font-size: 2rem;
		}
	}
	.h2-web {
		font-weight: 400;
		font-size: 1.875rem;
		line-height: 1.4;
	}
	.h3-web {
		font-weight: 400;
		font-size: 1rem;
		line-height: 1.25;
	}
	.h4-web {
		font-weight: 700;
		font-size: 0.875rem;
		line-height: 1.4;
	}
	.p-web {
		font-weight: 300;
		font-size: 1rem;
		line-height: 1.25rem;
	}
	::selection {
		color: #fff;
		background-color: #23303b;
	}
}

@layer components {
	.nav-item {
		@apply px-3 py-1 mx-1;
	}

	/* Same as navbar height */
	.reset-space {
		@apply pt-[80px];
	}

	/* Dropdown */

	.dropdown {
		@apply relative;
	}
	.dropdown-toggle {
		@apply whitespace-nowrap cursor-pointer;
	}
	.dropdown-menu {
		@apply absolute z-[10] hidden m-0 p-[5px] text-[16px] text-theme text-left bg-clip-padding shadow-md rounded-sm bg-[#f2f2f2a6];
	}
	.dropdown-menu.show {
		@apply block left-0;
	}
	.dropdown-item {
		@apply block w-full p-3 text-black whitespace-nowrap bg-transparent border-0;
	}

	.lang-selector {
		/* @apply md:absolute xl:relative top-[40px] xl:top-0 lg:right-8 xl:right-0; */
	}
	.lang-selector .dropdown-item.active {
		@apply text-white;
	}

	/* owl carousel dots css */
	.owl-carousel.owl-theme .owl-dots .owl-dot.active span,
	.owl-theme .owl-dots .owl-dot:hover span {
		background: #23303b;
	}
	.owl-carousel.owl-theme .owl-nav.disabled + .owl-dots {
		margin-top: 50px;
	}
	.product-carousel.owl-carousel.owl-theme .owl-item img {
		object-fit: contain;
	}
	.full-width_carousel.owl-carousel.owl-theme .owl-item img {
		object-fit: cover;
	}
	.image-link img {
		object-fit: cover;
	}
	.full-width_carousel.owl-theme .owl-nav {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 41px;
	}
	.full-width_carousel.owl-theme .owl-nav .owl-prev,
	.full-width_carousel.owl-theme .owl-nav .owl-next {
		width: 48px;
		height: 48px;
		border-radius: 50% !important;
		background: rgba(5, 5, 5, 0.44) !important;
		font-size: 29px;
		line-height: 30px;
		color: #fff;
	}

	.vdo-wrapper {
		position: relative;
		width: 100%;
		height: 100vh;
		overflow: hidden;
	}
	.video-background {
		background: #000;
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		z-index: -99;
	}
	.video-background::after {
		display: block;
		width: 100%;
		height: 100%;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.3);
	}
	.video-foreground,
	.video-background iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	.video-foreground video {
		width: 100%;
		height: 100%;
		@apply object-cover sm:object-contain;
	}

	@media (min-aspect-ratio: 16/9) {
		.video-foreground {
			height: 300%;
			top: -100%;
		}
	}
	@media (max-aspect-ratio: 16/9) {
		.video-foreground {
			width: 300%;
			left: -100%;
		}
	}
	@media (max-width: 420px) {
		.video-foreground {
			width: 350%;
			left: -100%;
		}
	}
}

/* Structure format */
/* .structure-format {
    letter-spacing: 1.4px;
} */
.structure-format ol {
	list-style: auto;
	margin: 20px 0 20px 20px;
	display: block;
}
.structure-format ul {
	list-style: disc;
	margin: 20px 0 20px 20px;
	display: block;
}
.structure-format ol li,
.structure-format ul li {
	margin-bottom: 10px;
}
.structure-format strong {
	font-weight: 800;
}
.structure-format blockquote {
	padding: 20px;
	background-color: rgb(250, 250, 250);
	border-left: 5px solid rgb(87, 87, 87);
	margin: 20px 0;
}

.structure-format pre {
	margin: 20px 0;
	padding: 20px;
	background-color: rgb(233 246 255 / 67%);
}
.structure-format hr {
	margin: 40px 0;
	border: 2px solid #e2e2e2;
}

.structure-format p {
	padding-bottom: 15px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 300;
	font-family: "Inter", sans-serif;
}

.structure-format p:empty {
	padding: 25px;
}

.structure-format p:only-child:empty {
	padding: 0px;
	margin-top: -30px;
}

.responsive-image img {
	object-fit: cover;
}

.structure-format .structure-image:first-child {
	margin-top: 0;
}

.object-adjust-max-height img {
	@apply object-contain max-h-[195px];
}
.object-adjust-contain img {
	@apply object-contain;
}

/* privacy policy page styles */
.privacy-policy-cookie-script-table {
	overflow-x: auto;
	padding: 1rem 0;
}

.privacy-policy-cookie-script-table table {
	margin: 1rem 0;
}

.privacy-policy-cookie-script-table table tbody tr td {
	font-family: "FuturaLT-regular";
	font-size: 15px;
	line-height: 1.5rem;
}

.privacy-policy-cookie-script-table table tbody tr td a {
	text-decoration: underline;
}

.structured-text p {
	margin: 0.75rem 0;
}

.structured-text a {
	color: rgb(102, 102, 102);
	text-decoration: underline;
	cursor: pointer;
	font-weight: 600;
}

.structured-text h3 {
	margin: 1rem 0;
}

.structured-text :is(h1) {
	font-weight: 700;
	font-size: 2rem;
}

.structured-text :is(h3) {
	font-weight: 700;
	font-size: 1.25rem;
}

.structured-text :is(h4, h5, h6) {
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.5rem;
}

.structured-text :is(h1, h2, h3, h4, h5, h6) {
	margin: 1rem 0;
}

.structured-text ul {
	list-style: disc;
	padding-left: 1.5rem;
	margin: 1rem 0;
}
